<template>
	<div class="consultation">
		<div class="banner">
			<img :src="$img('/upload/web/consultation_banner.png')" alt="">
			<div class="info">
				<div class="tit">数据资讯</div>
				<div class="desc">纵揽业界前沿资讯，发现电商新锐观点</div>
			</div>
		</div>
		<div class="content">
			<div class="message_list">
				<div class="top_category">
					<div class="item" @click="chooseClass(index)" :class="active_class == index ? 'active' : '' " v-for="(item,index) in ConsultationClassList" :key="index">{{item.class_name}}</div>
				</div>
				<div class="list" v-if="ConsultationPageList.length">
					<div class="item" v-for="(item,index) in ConsultationPageList" :key="index" @click="toConsultation(item.id)">
						<div class="left_img">
							<img :src="$util.img(item.image_url)" alt="">
						</div>
						<div class="right_info">
							<div class="message_tit">{{item.title}}</div>
							<div class="message_content">{{item.introduction}}</div>
							<div class="bottom_detail">
								<div class="time"><i class="el-icon-time"></i>{{ $util.timeStampTurnTime(item.create_time).split(' ')[0] }}</div>
								<div class="browse"><i class="el-icon-view"></i>{{item.read}}</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="empty_text">暂无资讯</div>
				<div class="pager">
					<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
				</div>
			</div>
			<div class="hot_news">
				<div class="tit">热门资讯</div>
				<div class="news">
					<div class="item" @click="toConsultation(item.id)" :class="index == 0 ? 'first' : ( index == 1 ? 'second' : ( index == 2 ? 'three' : '' ) ) " v-for="(item,index) in hotConsultationList" :key="index">
						<div class="number">0{{index+1}}</div>
						<div class="news_info">{{item.title}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ConsultationPageList,ConsultationClassList } from "@/api/index.js"
	export default {
		data() {
			return {
				hotConsultationList:[],
				ConsultationPageList:[],
				ConsultationClassList:[],
				active_class:0,
				total:0,
				currentPage: 1,
				pageSize: 8,
			}
		},
		created() {
			this.getConsultationClassList();
			this.getHotConsultationList();
		},
		methods: {
			getHotConsultationList(){
				ConsultationPageList({
					page:1,
					page_size:10,
					type:2
				})
				.then(res=>{
					if(res.code>=0){
						this.hotConsultationList = res.data.list;
					}
				})
			},
			toConsultation(id){
				this.$router.pushToTab('/consultation/detail-'+id)
			},
			handlePageSizeChange(size) {
			    this.pageSize = size
				this.getConsultationClassList();
			},
			handleCurrentPageChange(page) {
			    this.currentPage = page
				this.getConsultationClassList();
			},
			getConsultationPageList(){
				ConsultationPageList({
					class_id:this.ConsultationClassList[this.active_class].class_id,
					page:this.currentPage,
					page_size:this.page_size
				})
				.then(res=>{
					if(res.code>=0){
						this.ConsultationPageList = res.data.list;
						this.total = res.data.count;
					}
				})
			},
			chooseClass(index){
				this.active_class = index;
				this.getConsultationPageList()
			},
			getConsultationClassList() {
				ConsultationClassList()
				.then(res=>{
					if(res.code>=0){
						this.ConsultationClassList = [{class_name:'全部',class_id:''}]
						this.ConsultationClassList.push(...res.data);
						this.getConsultationPageList();
					}
				})
			}
		},
	}
</script>

<style lang="scss">
	body{
		background-color: #F8F8F8 !important;
	}
</style>
<style lang="scss" scoped>
	.consultation{
		width: 1200px;
		margin: 20px auto 0;
		font-family: pingfang;
		.banner{
			position: relative;
			margin-bottom:20px;
			img{
				display: block;
			}
			.info{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 8.1%;
				.tit{
					color: #fff;
					font-size: 34px;
					line-height: 40px;
					margin-bottom: 8px;
					font-weight: 600;
				}
				.desc{
					color: #fff;
					font-size: 17px;
					line-height: 24px;
				}
			}
		}
		.content{
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 20px;
			.hot_news{
				width: 314px;
				background-color: #fff;
				.tit{
					padding: 20px 20px 18px;
					font-size: 16px;
					color: #333;
					line-height: 22px;
					border-bottom: 1px solid #F4F5F7;
				}
				.news{
					padding: 18px 24px;
					.item{
						cursor: pointer;
						display: flex;
						align-items: center;
						margin-bottom: 18px;
						&:last-child{
							margin-bottom: 0;
						}
						&.first{
							.number{
								background-color: #FF4761;
								color: #fff;
							}
						}
						&.second{
							.number{
								background-color: #FA6400;
								color: #fff;
							}
						}
						&.three{
							.number{
								background-color: #F7B500;
								color: #fff;
							}
						}
						.number{
							color: #666;
							font-size: 14px;
							line-height: 14px;
							padding: 8px 6px 6px;
							margin-right: 10px;
						}
						.news_info{
							color: #666;
							font-size: 13px;
							line-height: 16px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
					}
				}
			}
			.message_list{
				flex: 1;
				margin-right: 20px;
				background-color: #fff;
				padding-bottom: 30px;
				.empty_text{
					text-align: center;
					padding: 20px 10px 10px;
				}
				.top_category{
					padding: 20px 20px 0;
					display: flex;
					align-items: center;
					border-bottom:1px solid #F4F5F7;
					.item{
						color: #333;
						font-size: 18px;
						line-height: 24px;
						border-bottom: 3px solid transparent;
						padding-bottom: 13px;
						margin-right: 60px;
						cursor: pointer;
						&.active{
							font-weight: 600;
							color: #0DAD94;
							border-color: #0DAD94;
						}
					}
				}
				.list{
					padding: 0 46px 0 20px;
					display: flex;
					align-items: center;
					flex-direction: column;
					.item{
						width: 100%;
						display: flex;
						align-items: center;
						margin-top: 30px;
						.left_img{
							min-width: 240px;
							width: 240px;
							height: 120px;
							margin-right: 20px;
							cursor: pointer;
							img{
								width: 100%;
								height: 100%;
								display: block;
							}
						}
						.right_info{
							display:flex;
							flex-direction: column;	
							.message_tit{
								color: #333;
								font-size: 16px;
								line-height: 22px;
								margin-bottom: 7px;
								font-weight: 600;
							}
							.message_content{
								color: #888;
								font-size: 13px;
								line-height: 20px;
								height: 40px;
								margin-bottom: 20px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
							}
						}
						.bottom_detail{
							display: flex;
							align-items: center;
							color:#A2A2A2;
							.time{
								margin-right: 45px;
							}
							.time,.browse{
								display: flex;
								align-items: center;
								color:#A2A2A2;
								font-size: 12px;
								line-height: 17px;
								i{
									margin-right: 6px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
import http from "../utils/http"

/**
 * 发送消息
 * @param {object} params
 */
export function RecentTransactions(params) {
    return http({
        url: "/api/pc/getRecentTransactions",
        data: params
    })
}
export function MarkingEnterprise(params) {
    return http({
        url: "/api/shop/getMarkingEnterprise",
        data: params
    })
}
export function MarketData(params) {
    return http({
        url: "/api/pc/getMarketData",
        data: params
    })
}

export function InquiryDynamics(params) {
    return http({
        url: "/api/pc/getInquiryDynamics",
        data: params
    })
}
export function SupplyDemandPageList(params) {
    return http({
        url: "/api/desupplydemand/getSupplyDemandPageList",
        data: params
    })
}
export function ConsultationPageList(params) {
    return http({
        url: "/api/deconsultation/getConsultationPageList",
        data: params
    })
}
export function ConsultationClassList(params) {
    return http({
        url: "/api/deconsultation/getConsultationClassList",
        data: params
    })
}
export function ReadConsultationInfo(params) {
    return http({
        url: "/api/deconsultation/getReadConsultationInfo",
        data: params
    })
}
export function addSupplydemand(params) {
    return http({
        url: "/api/desupplydemand/addSupplydemand",
        data: params
    })
}
export function SupplyDemandInfo(params) {
    return http({
        url: "/api/desupplydemand/getSupplyDemandInfo",
        data: params
    })
}
export function mobileCode(params) {
    return http({
        url: "/api/desupplydemand/mobileCode",
        data: params
    })
}